/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentData } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { map } from 'rxjs/operators';
import { Challenge } from './challenge.model';
import { NotificationService } from './notification.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ChallengeService {

    challenge: AngularFirestoreCollection;
    challengesRef: AngularFirestoreCollection;

    constructor(
        private db: AngularFirestore,
        private storage: AngularFireStorage,
        private ns: NotificationService
    ) {
        this.challenge = this.db.collection('challenge');
        this.challengesRef = this.db.collection('challenges');
    }

    public async createChallenge(challenge: Challenge) {
        const data = await this.challengesRef.add({
            name: challenge.name,
            extraText: challenge.extraText,
            rulesPdf: challenge.rulesPdf ? challenge.rulesPdf : null,
            rulesMarkdown: challenge.rulesMarkdown ? challenge.rulesMarkdown : null,
            startDate: challenge.startDate != null && challenge.startDate.isValid() ? challenge.startDate.format() : null,
            endDate: challenge.endDate && challenge.endDate.isValid() ? challenge.endDate.format() : null,
            image: challenge.image ? challenge.image : null,
        });
        return data;
    }

    public async uploadChallengePdf(pdf: File): Promise<string> {
        const data = await this.storage.upload(`/challengeFiles/${Date.now()}.pdf`, pdf);
        const url = await data.ref.getDownloadURL();
        return url;
    }

    public async uploadChallengeMarkdown(markdown: File): Promise<string> {
        const data = await this.storage.upload(`/challengeFiles/${Date.now()}.md`, markdown);
        const url = await data.ref.getDownloadURL();
        return url;
    }

    public async uploadChallengeImage(image: File): Promise<string> {
        const data = await this.storage.upload('/challengeFiles/' + Date.now().toString(), image);
        const url = await data.ref.getDownloadURL();
        return url;
    }

    public async updateChallenge(challenge: Challenge) {
        await this.challengesRef.doc(challenge.id).update({
            name: challenge.name,
            extraText: challenge.extraText,
            rulesPdf: challenge.rulesPdf ? challenge.rulesPdf : null,
            rulesMarkdown: challenge.rulesMarkdown ? challenge.rulesMarkdown : null,
            startDate: challenge.startDate != null && challenge.startDate.isValid() ? challenge.startDate.format() : null,
            endDate: challenge.endDate && challenge.endDate.isValid() ? challenge.endDate.format() : null,
            image: challenge.image ? challenge.image : null,
        });
    }

    public getChallenge(id: string) {
        return this.challengesRef.doc(id).snapshotChanges().pipe(
            map((response: DocumentData) => {

                const challenge = response.payload.data() as Challenge;
                if (challenge.startDate) {
                    challenge.startDate = moment(challenge.startDate);
                }
                if (challenge.endDate) {
                    challenge.endDate = moment(challenge.endDate);
                }
                challenge.id = response.payload.id as string;
                return challenge;
            })
        );
    }

    public getChallenges() {
        return this.challengesRef.snapshotChanges().pipe(
            map((response: any) => response.map(item => {
                const challenge = item.payload.doc.data() as Challenge;
                challenge.id = item.payload.doc.id;
                if (challenge.startDate != null) {
                    challenge.startDate = moment(challenge.startDate);
                }
                if (challenge.endDate != null) {
                    challenge.endDate = moment(challenge.endDate);
                }
                return challenge;
            }))
        );
    }

    public deleteChallenge(id: string) {
        return this.challengesRef.doc(id).delete().then(() => {
            this.ns.setMessage('Reto eliminado correctamente');
        });
    }
}
