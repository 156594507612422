import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { Contest, Category, CategoryPoints, Price, ExchangedPrice } from './contest.model';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PricesService {

    priceImagesRef: AngularFireStorageReference;
    imagesRef: AngularFirestoreCollection;
    contestRef: AngularFirestoreCollection;

    constructor(
        private storage: AngularFireStorage,
        private db: AngularFirestore
    ) {
        this.priceImagesRef = this.storage.ref('priceImages');
        this.imagesRef = this.db.collection('images');
        this.contestRef = this.db.collection('contests');
    }

    public uploadImage(image: File) {
        return this.storage.upload('/priceImages/' + Date.now(), image).then(
            data => data.ref.getDownloadURL().then(
                url => {
                    this.imagesRef.add({
                        url
                    });
                    return url;
                }
            )
        );
    }

    public getImages() {
        return this.imagesRef.valueChanges();
    }

    public setPrices(prices: Price[], id: string) {
        const calls = [];
        for (const price of prices) {
            calls.push(this.contestRef.doc(id).collection('prices').add(
                {
                    name: price.name,
                    image: price.image,
                    url: price.url,
                    categories: price.categories
                }
            ));
        }
        return forkJoin(calls);
    }

    public updatePrices(prices: Price[], id: string) {
        const calls = [];
        for (const price of prices) {
            if (price.id) {
                calls.push(
                    this.contestRef.doc(id).collection('prices').doc(price.id).set(
                        {
                            name: price.name,
                            image: price.image,
                            url: price.url,
                            categories: price.categories
                        }
                    )
                );
            } else {
                calls.push(
                    this.contestRef.doc(id).collection('prices').add(
                        {
                            name: price.name,
                            image: price.image,
                            url: price.url,
                            categories: price.categories
                        }
                    )
                );
            }

        }
        return forkJoin(calls);
    }

    public getPrices(id: string): Observable<Price[]> {
        return this.contestRef.doc(id).collection('prices').snapshotChanges().pipe(
            map(data => data.map(item => {
                const price = item.payload.doc.data() as Price;
                price.id = item.payload.doc.id;
                return price;
            }))
        );
    }

    public deletePrice(id: string, priceId: string) {
        return this.contestRef.doc(id).collection('prices').doc(priceId).delete();
    }

    public deletePrices(owner: string, prices: string[]) {
        const calls = [];
        for (const price of prices) {
            calls.push(
                this.contestRef.doc(owner).collection('prices').doc(price).delete()
            );
        }
        return forkJoin(calls);
    }

    public getExchangedPrices(owner: string) {
        return this.contestRef.doc(owner).collection('exchanged').snapshotChanges().pipe(
            map(data => {
                const results = [];
                data.map(item => {
                    const obj = item.payload.doc.data();
                    const index = results.findIndex(item => item.priceId == obj.priceId);
                    if (index != -1) {
                        results[index].quantity += obj.quantity;
                        results[index].points += obj.points;
                    } else {
                        const exchanged = obj as ExchangedPrice;
                        results.push(exchanged);
                    }
                });
                return results;
            })
        );
    }

    public exchangePrice(owner: string, priceId: string, points: number) {
        return this.contestRef.doc(owner).collection('exchanged').add({
            priceId,
            quantity: 1,
            points
        });
    }

}
