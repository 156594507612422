/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { Contest, TextAsset, MarkdownAsset, ExchangedPrice } from './contest.model';
import { Observable, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { UploadMetadata } from '@angular/fire/compat/storage/interfaces';

@Injectable({
    providedIn: 'root'
})
export class ContestService {

    contestsRef: AngularFirestoreCollection;

    constructor(
        private db: AngularFirestore,
        private storage: AngularFireStorage,
        private ns: NotificationService
    ) {
        this.contestsRef = this.db.collection('contests');
    }

    //* ************ Contest info functions ***************//

    public getContestList(): Observable<Contest[]> {
        return this.contestsRef.snapshotChanges().pipe(
            map(response => response.map(item => this.parseContest(item.payload.doc.data(), item.payload.doc.id)))
        );
    }

    public getContest(id: string): Observable<Contest> {
        return this.contestsRef.doc(id).snapshotChanges().pipe(
            map(response => this.parseContest(response.payload.data(), response.payload.id))
        );
    }

    public createContest(contest: Contest) {
        return this.contestsRef.doc(contest.id).set({
            name: contest.name,
            extraText: contest.extraText,
            startDate: contest.startDate.format(),
            endDate: contest.endDate ? contest.endDate.format() : null,
            type: contest.type ? contest.type : null,
            company: contest.company ? contest.company : null,
            companyAddress: contest.companyAddress ? contest.companyAddress : null,
            image: contest.image ? contest.image : null,
            noRatioPoints: contest.noRatioPoints ? contest.noRatioPoints : null
        });
    }

    public updateContest(contest: Contest) {
        return this.contestsRef.doc(contest.id).update({
            name: contest.name,
            extraText: contest.extraText,
            startDate: contest.startDate.format(),
            endDate: contest.endDate ? contest.endDate.format() : null,
            type: contest.type ? contest.type : null,
            company: contest.company ? contest.company : null,
            companyAddress: contest.companyAddress ? contest.companyAddress : null,
            image: contest.image ? contest.image : null,
            noRatioPoints: contest.noRatioPoints ? contest.noRatioPoints : null
        });
    }

    public deleteContest(id: string) {
        this.contestsRef.doc(id).delete().then(() => {
            this.ns.setMessage('Se ha eliminado el concurso');
        }).catch(() => {});
    }

    public async setContestRules(rules: TextAsset, ownerId: string) {
        const r = rules.images.map(item => {
            if (item.imageFile) {
                return {
                    id: item.id,
                    url: ''
                };
            } else {
                return {
                    id: item.id,
                    url: item.url
                };
            }
        });
        await this.contestsRef.doc(ownerId).update({
            contestRules: {
                content: rules.text,
                images: r
            }
        });
        rules.images.forEach((asset: MarkdownAsset, index: number) => {
            if (asset.imageFile) {
                this.storage.upload(`/contestImages/${Date.now()}`, asset.imageFile).then(data => {
                    data.ref.getDownloadURL().then(url => {
                        rules.images[index].url = url;
                        const nr = rules.images.map(item => ({
                            id: item.id,
                            url: item.url
                        }));
                        this.contestsRef.doc(ownerId).update({
                            contestRules: {
                                content: rules.text,
                                images: nr
                            }
                        }).catch(() => {});
                    }).catch(() => {});
                }).catch(() => {});
            }
        });
    }

    public setContestRulesPdf(pdf: any, ownerId: string) {
        return this.storage.upload(`/contestFiles/${Date.now()}.pdf`, pdf).then(data => {
            data.ref.getDownloadURL().then(url => {
                this.contestsRef.doc(ownerId).update({
                    contestRulesPdf: url
                }).catch(() => {});
            }).catch(() => {});
        });
    }

    public setContestRulesMarkdownFile(markdown: any, ownerId: string) {
        return this.storage.upload(`/contestFiles/${Date.now()}.md`, markdown).then(data => {
            data.ref.getDownloadURL().then(url => {
                this.contestsRef.doc(ownerId).update({
                    contestRulesMarkdown: url
                }).catch(() => {});
            }).catch(() => {});
        });
    }

    public async setFinalReport(report: TextAsset, ownerId: string) {
        const r = report.images.map(item => {
            if (item.imageFile) {
                return {
                    id: item.id,
                    url: ''
                };
            } else {
                return {
                    id: item.id,
                    url: item.url
                };
            }
        });
        await this.contestsRef.doc(ownerId).update({
            finalReport: {
                content: report.text,
                images: r
            }
        });
        report.images.forEach((asset: MarkdownAsset, index: number) => {
            if (asset.imageFile) {
                this.storage.upload(`/contestImages/${Date.now()}`, asset.imageFile).then(data => {
                    data.ref.getDownloadURL().then(url => {
                        report.images[index].url = url;
                        const nr = report.images.map(item => ({
                            id: item.id,
                            url: item.url
                        }));
                        this.contestsRef.doc(ownerId).update({
                            finalReport: {
                                content: report.text,
                                images: nr
                            }
                        }).catch(() => {});
                    }).catch(() => {});
                }).catch(() => {});
            }
        });
    }

    public setFinalReportPdf(pdf: any, ownerId: string): Promise<any> {
        return this.storage.upload(`/contestFiles/${Date.now()}.pdf`, pdf).then(data => {
            data.ref.getDownloadURL().then(url => {
                this.contestsRef.doc(ownerId).update({
                    finalReportPdf: url
                }).catch(() => {});
            }).catch(() => {});
        });
    }

    public setFinalReportMarkdown(pdf: any, ownerId: string): Promise<any> {
        return this.storage.upload(`/contestFiles/${Date.now()}.md`, pdf).then(data => {
            data.ref.getDownloadURL().then(url => {
                this.contestsRef.doc(ownerId).update({
                    finalReportMarkdown: url
                }).catch(() => {});
            }).catch(() => {});
        });
    }

    public setContestSponsors(items: any[], ownerId: string) {
        return this.contestsRef.doc(ownerId).update({
            sponsors: items
        });
    }

    public async uploadContestImage(image: File): Promise<string> {
        const data = await this.storage.upload(`/contestsFiles/${Date.now()}`, image);
        const url = await data.ref.getDownloadURL();
        return url;
    }

    //* ******************** Parsing methods *********************//

    private parseContest(info: any, id: string): Contest {
        const contest = new Contest();
        contest.id = id;
        // const data = (info as unknown) as Contest;
        if (info === undefined) { return contest; }
        contest.name = info.name as string;
        contest.extraText = info.extraText as string;
        contest.startDate = info.startDate ? moment(info.startDate) : null;
        contest.endDate = info.endDate ? moment(info.endDate) : null;
        contest.contestRules = new TextAsset();
        contest.type = info.type as string;
        contest.noRatioPoints = info.noRatioPoints as boolean;
        contest.company = info.company;
        contest.companyAddress = info.companyAddress;
        contest.image = info.image;
        if (info.contestRules) {
            contest.contestRules.text = info.contestRules.content;
            contest.contestRules.images = info.contestRules.images as MarkdownAsset[];
            contest.contestRules.markdownFile = info.contestRules.markdownFile;
        } else {
            contest.contestRules.text = '';
            contest.contestRules.images = [];
        }
        contest.finalReport = new TextAsset();
        if (info.finalReport) {
            contest.finalReport.text = info.finalReport.content;
            contest.finalReport.images = info.finalReport.images as MarkdownAsset[];
        } else {
            contest.finalReport.text = '';
            contest.finalReport.images = [];
        }
        contest.sponsors = info.sponsors;
        contest.contestRulesPdf = info.contestRulesPdf;
        contest.contestRulesMarkdown = info.contestRulesMarkdown;
        contest.finalReportPdf = info.finalReportPdf;
        contest.finalReportMarkdown = info.finalReportMarkdown;
        if (info.exchanged) {
            contest.exchanged = [];
            for (const item of info.exchanged) {
                const exp = new ExchangedPrice();
                exp.priceId = item.priceId;
                exp.quantity = item.quantity;
                exp.points = item.points;
                contest.exchanged.push(exp);
            }
        }
        return contest;
    }
}
