import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private messageString: string;
    private display = false;

    constructor() { }

    get message(): string {
        return this.messageString;
    }

    get shouldDisplay(): boolean {
        return this.display;
    }

    public setMessage(value: string) {
        this.messageString = value;
        if (this.display) {
            this.display = false;
            setTimeout(() => {
                this.display = true;
                setTimeout(() => {
                    this.display = false;
                });
            }, 1000);
        } else {
            this.display = true;
            setTimeout(() => {
                this.display = false;
            }, 3000);
        }
    }

    public close() {
        this.display = false;
    }
}
