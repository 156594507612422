import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SEOService {

    constructor(
        private title: Title,
        private meta: Meta,
        private router: Router
    ) {
        this.addSeoData();
    }

    public addSeoData(): void {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                let root = this.router.routerState.snapshot.root;
                while (root) {
                    if (root.children && root.children.length) {
                        root = root.children[0];
                    } else if (root.data && root.data.title) {
                        this.title.setTitle(root.data.title);
                        const tags = root.data.metatags;
                        for (const tag in tags) {
                            this.meta.updateTag({ name: tag, content: tags[tag] });
                        }
                        return;
                    } else {
                        return;
                    }
                }
            }
        });
    }
}
