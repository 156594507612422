import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { School } from './participant.model';
import { ProductPickup } from './product-pickup.model';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ProductPickupService {

    contestRef: AngularFirestoreCollection;

    constructor(
        private db: AngularFirestore
    ) {
        this.contestRef = this.db.collection('contests');
    }

    public createProductPickup(ownerId: string, pickup: ProductPickup) {
        return this.contestRef.doc(ownerId).collection('productPickups').add({
            school: pickup.school ? pickup.school : null,
            schoolName: pickup.schoolName ? pickup.schoolName : null,
            category: pickup.category,
            product: pickup.product,
            productName: pickup.productName,
            weight: pickup.weight,
            totalPoints: pickup.totalPoints,
            ratioPoints: pickup.ratioPoints ? pickup.ratioPoints : null,
            date: moment().format()
        });
    }

    public getProductPickups(ownerId: string) {
        return this.contestRef.doc(ownerId).collection('productPickups').snapshotChanges().pipe(
            map((data: any) => data.map(item => {
                const pp = item.payload.doc.data() as ProductPickup;
                pp.weight = Number(pp.weight);
                pp.id = item.payload.doc.id;
                return pp;
            }))
        );
    }

    public deleteProductPickup(ownerId: string, productPickupId: string) {
        return this.contestRef.doc(ownerId)
            .collection('productPickups').doc(productPickupId).delete();
    }
}
