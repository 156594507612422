/* eslint-disable @typescript-eslint/member-ordering */
import * as moment from 'moment';
import { AwardRuleType } from './enums/award-rule-type.enum';

export class Contest {
    id: string;
    name: string;
    extraText: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    categories: Category[];
    prices: Price[];
    priceRules: AwardRule[];
    contestRules: TextAsset;
    contestRulesPdf: string;
    contestRulesMarkdown: string;
    finalReport: TextAsset;
    finalReportPdf: string;
    finalReportMarkdown: string;
    sponsors: Sponsor[];
    company?: string;
    companyAddress?: string;
    type?: string;
    image?: string;
    exchanged?: ExchangedPrice[];
    noRatioPoints: boolean;

    isActive(): boolean {
        if (this.endDate) {
            if (moment().isSameOrAfter(moment(this.endDate))) {
                return false;
            }
        }
        if (moment().isBefore(moment(this.startDate))) {
            return true;
        } else if (moment().isSameOrAfter(moment(this.startDate))) {
            return true;
        }
    }

    get status(): string {
        if (this.endDate) {
            if (moment().isAfter(moment(this.endDate))) {
                return 'past';
            }
        }
        if (moment().isBefore(moment(this.startDate))) {
            return 'future';
        } else if (moment().isSameOrAfter(moment(this.startDate))) {
            return 'active';
        }
    }
}

export class Category {
    id: string;
    products: CategoryPoints[];
    isTemplate?: boolean;
}

export class CategoryPoints {
    identifier: string;
    description: string;
    points: string;
}

export class Price {
    id: string;
    name: string;
    url: string;
    image: string;
    imageFile: File;
    categories: string[];
}

export class AwardRule {
    id: string;
    type: AwardRuleType;
    items: AwardRuleItem[];
}

export class AwardRuleItem {
    points: number;
    prices: any[];
    position: number;
}

export class TextAsset {
    text?: string;
    images?: MarkdownAsset[];
    markdownFile?: string;
}

export class MarkdownAsset {
    id: string;
    url: string;
    imageFile: File;
}

export class Sponsor {
    id?: string;
    url?: string;
    image?: string;
    name?: string;
}

export class ExchangedPrice {
    priceId: string;
    quantity: number;
    points: number;
}
