// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // firebase: {
    //     apiKey: 'AIzaSyAYyBOql2TZOoCu-tyG6qhkfu9OLZHc7_0',
    //     authDomain: 'development-4.firebaseapp.com',
    //     databaseURL: 'https://development-4.firebaseio.com',
    //     projectId: 'development-4',
    //     storageBucket: 'development-4.appspot.com',
    //     messagingSenderId: '416378316742',
    //     appId: '1:416378316742:web:e33e98f77b12345831f4c7',
    //     measurementId: 'G-LCVL3KGNN6',
    // },
    // apiUrl: 'https://us-central1-development-4.cloudfunctions.net'
    // apiUrl: 'http://127.0.0.1:5001/development-4/us-central1/participantSubmit'
    firebase: {
        apiKey: 'AIzaSyDTayUTp4kI78XAws8P_2oMIWBbIRUm-2M',
        authDomain: 'concurso-la-hormiga-verde.firebaseapp.com',
        databaseURL: 'https://concurso-la-hormiga-verde.firebaseio.com',
        projectId: 'concurso-la-hormiga-verde',
        storageBucket: 'concurso-la-hormiga-verde.appspot.com',
        messagingSenderId: '173290046005',
        appId: '1:173290046005:web:a204324c86945b8c72e77c',
        measurementId: 'G-88PFZTSGK6'
    },
    apiUrl: 'https://us-central1-concurso-la-hormiga-verde.cloudfunctions.net'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
