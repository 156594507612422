import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SEOService } from './seo.service';
import { ContestService } from './contest.service';
import { CategoriesService } from './categories.service';
import { PricesService } from './prices.service';
import { AdminService } from './admin.service';
import { AwardRulesService } from './award-rules.service';
import { SponsorsService } from './sponsors.service';
import { AdminGuard } from './admin.guard';
import { LandingService } from './landing.service';
import { ParticipantsService } from './participants.service';
import { ChallengeService } from './challenge.service';
import { NotificationService } from './notification.service';
import { AboutUsService } from './about-us.service';
import { RSCService } from './rsc.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        SEOService, AdminGuard
    ]
})
export class ModelModule {
    static forRoot(): ModuleWithProviders<ModelModule> {
        return {
            ngModule: ModelModule,
            providers: [
                SEOService, ContestService,
                CategoriesService, PricesService,
                AdminService, AwardRulesService,
                SponsorsService, LandingService,
                ParticipantsService, ChallengeService,
                NotificationService, AboutUsService,
                RSCService
            ]
        };
    }
}
