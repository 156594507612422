/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentData } from '@angular/fire/compat/firestore';
import { Contest, AwardRule } from './contest.model';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AwardRulesService {

    awardRulesRef: AngularFirestoreCollection<AwardRule>;
    contestsRef: AngularFirestoreCollection<Contest>;

    constructor(
        private db: AngularFirestore
    ) {
        this.contestsRef = this.db.collection('contests');
    }

    public setAwardRules(ownerId: string, rules: AwardRule[]) {
        const calls = [];
        rules.forEach((element: AwardRule) => {
            const array = [];
            for (const collection of element.items) {
                array.push({
                    points: collection.points,
                    prices: collection.prices,
                    position: collection.position
                });
            }
            calls.push(
                this.contestsRef.doc(ownerId).collection('awardRules').doc(element.id).set(
                    {
                        items: array,
                        type: element.type
                    }
                )
            );
        });
        return forkJoin(calls);
    }

    public getAwardRules(ownerId: string): Observable<any[]> {
        return this.contestsRef.doc(ownerId).collection('awardRules').snapshotChanges().pipe(
            map((data: DocumentData[]) => data.map((rule: DocumentData) => {
                const items = rule.payload.doc.data().items as AwardRule[];
                const r = {
                    id: rule.payload.doc.id as string,
                    items,
                    type: rule.payload.doc.data().type as string
                };
                return r;
            }))
        );
    }

    public getStaticAwardRules(ownerId: string) {
        return this.contestsRef.doc(ownerId).collection('awardRules').get().pipe(
            map((data: any) => data.docs.map(rule => {
                const items = rule.data().items as AwardRule[];
                const r = {
                    id: rule.id as string,
                    items,
                    type: rule.data().type as string
                };
                return r;
            }))
        );
    }
}
