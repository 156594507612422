/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { RSCCategory, RSCItem, RSCInfo, Stats } from './rsc.model';
import { WebService } from './web.service';
import { ProductPickup } from './product-pickup.model';
import { Items } from './rscTemplate';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RSCService {
    apiUrl = environment.apiUrl;

    categories: RSCCategory[];
    rscItems: RSCItem[];
    rscInfo: RSCInfo;

    constructor(
        private db: AngularFirestore,
        private ws: WebService,
        private http: HttpClient
    ) {
        // this.db.collection('rscTemplates').doc('RAEEs').get().subscribe(
        //     data => {
        //         const array = [];
        //         if (!data.data()) {
        //             return;
        //         }
        //         (data.data() as any).items.forEach(item => {
        //             const c = new RSCCategory();
        //             c.identifier = item.identifier;
        //             c.feContent = Number(item.ferrico);
        //             c.alContent = Number(item.aluminio);
        //             c.cuContent = Number(item.cobre);
        //             c.plContent = Number(item.plasticos);
        //             c.elContent = Number(item.electronica);
        //             array.push(c);
        //         });
        //         this.categories = array;
        //     }
        // );
    }

    // public calculateRSC() {
    //     if (this.ws.productPickups && this.categories) {
    //         this.rscItems = [];
    //         this.categories.forEach((cat: RSCCategory) => {
    //             const pickups = this.ws.productPickups.filter((item: ProductPickup) => item.productName === cat.identifier);
    //             const result = new RSCItem();
    //             result.category = cat.identifier;
    //             result.kgs = 0;
    //             pickups.forEach((pp: ProductPickup) => {
    //                 result.kgs += Number(pp.weight);
    //             });
    //             result.fe = Math.round(result.kgs * cat.feContent / 100);
    //             result.al = Math.round(result.kgs * cat.alContent / 100);
    //             result.cu = Math.round(result.kgs * cat.cuContent / 100);
    //             result.pl = Math.round(result.kgs * cat.plContent / 100);
    //             result.el = Math.round(result.kgs * cat.elContent / 100);
    //             this.rscItems.push(result);
    //         });
    //         this.rscInfo = new RSCInfo(this.rscItems);
    //     }
    // }

    // get info(): RSCInfo {
    //     this.calculateRSC();
    //     return this.rscInfo;
    // }

    public getContestStats(contestId: string): Observable<Stats> {
        return this.http.post<Stats>(`${this.apiUrl}/getContestStats`, {contestId});
    }

    public getGlobalStats(): Observable<Stats> {
        return this.http.get<Stats>(`${this.apiUrl}/getStats`);
    }
}
