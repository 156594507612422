/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { School } from './participant.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ParticipantsService {

    apiUrl = environment.apiUrl;

    contestRef: AngularFirestoreCollection;

    constructor(
        private db: AngularFirestore,
        private ns: NotificationService,
        private http: HttpClient
    ) {
        this.contestRef = this.db.collection('contests');
    }

    public getSchools(ownerId: string): Observable<School[]> {
        return this.contestRef.doc(ownerId).collection('schools').snapshotChanges().pipe(
            map((data: any) => data.map(item => {
                const school = item.payload.doc.data() as School;
                school.id = item.payload.doc.id;
                return school;
            }))
        );
    }

    public getNewSchools(ownerId: string): Observable<any[]> {
        return this.contestRef.doc(ownerId).collection('newSchools').snapshotChanges().pipe(
            map((data: any) => data.map(item => {
                const info = item.payload.doc.data();
                return {
                    id: item.payload.doc.id,
                    schoolId: info.id,
                    name: info.name
                };
            }))
        );
    }

    public createSchool(owner: School, school: School) {
        // Send Email
        const participant = {
            name: school.name,
            cif: school.cif,
            studentCount: school.studentCount ? school.studentCount : null,
            email: school.email,
            telephone: school.telephone,
            address: school.address,
            city: school.city,
            province: school.province,
            postalCode: school.postalCode,
            contactName: school.contactName,
        };
        const body = {
            contest: owner.id,
            participant
        };
        this.http.post<any>(`${this.apiUrl}/setupParticipant`, body).subscribe((res: string) => {
            console.log(res);
        }, (e) => {
            console.log('error', e);
        });
        // Add school to collection
        return this.contestRef.doc(owner.id).collection('schools').add({
            name: school.name,
            cif: school.cif,
            studentCount: school.studentCount ? school.studentCount : null,
            email: school.email,
            telephone: school.telephone,
            address: school.address,
            city: school.city,
            province: school.province,
            postalCode: school.postalCode,
            contactName: school.contactName,
            position: school.position ? school.position : null
        }).then(response => this.contestRef.doc(owner.id).collection('newSchools').add({
            name: school.name,
            id: response.id
        }));
    }

    public adminCreateSchool(ownerId: string, school: School) {
        return this.contestRef.doc(ownerId).collection('schools').add({
            name: school.name,
            cif: school.cif,
            studentCount: school.studentCount ? school.studentCount : null,
            email: school.email,
            telephone: school.telephone,
            address: school.address,
            city: school.city,
            province: school.province,
            postalCode: school.postalCode,
            contactName: school.contactName,
            position: school.position ? school.position : null
        });
    }

    public updateSchool(ownerId: string, info: School) {
        return this.contestRef.doc(ownerId).collection('schools').doc(info.id).update({
            name: info.name,
            cif: info.cif,
            studentCount: info.studentCount,
            email: info.email,
            telephone: info.telephone,
            address: info.address,
            city: info.city,
            province: info.province,
            postalCode: info.postalCode,
            contactName: info.contactName
        });
    }

    public deleteSchool(ownerId: string, id: string) {
        return this.contestRef.doc(ownerId).collection('schools').doc(id).delete().then(() => {
            this.ns.setMessage('Concursante eliminado correctamente');
        });
    }

    public deleteNewSchool(ownerId: string, id: string) {
        return this.contestRef.doc(ownerId).collection('newSchools').doc(id).delete().then(() => {
            this.ns.setMessage('Concursante procesado correctamente');
        });
    }
}
