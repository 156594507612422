import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Contest, Category, Price, AwardRule, Sponsor, TextAsset } from './contest.model';
import { ProductPickup } from './product-pickup.model';
import { ContestService } from './contest.service';
import { CategoriesService } from './categories.service';
import { PricesService } from './prices.service';
import { AwardRulesService } from './award-rules.service';
import { ParticipantsService } from './participants.service';
import { ProductPickupService } from './product-pickup.service';
import { School } from './participant.model';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    activeContest: Observable<Contest>;

    activeCategories: Observable<Category[]>;

    activePrices: Observable<Price[]>;

    activeAwardRules: Observable<any[]>;

    activeSchools: Observable<School[]>;

    currentId: string;

    selectedContestChanged = new EventEmitter<void>();

    constructor(
        private cs: ContestService,
        private cts: CategoriesService,
        private ps: PricesService,
        private ars: AwardRulesService,
        private pts: ParticipantsService,
        private pps: ProductPickupService
    ) {}

    public setSelectedContest(id?: string) {
        if (id) {
            this.currentId = id;
            this.activeContest = this.cs.getContest(id);
            this.activeCategories = this.cts.getContestCategories(id);
            this.activePrices = this.ps.getPrices(id);
            this.activeAwardRules = this.ars.getAwardRules(id);
            this.activeSchools = this.pts.getSchools(id);
            this.selectedContestChanged.emit();
        }
    }

    public clearSelection() {
        this.currentId = null;
        this.activeContest = null;
        this.activeCategories = null;
        this.activeAwardRules = null;
    }

    //* ************************** Update methods ******************************//

    public updateContestInfo(info: Contest) {
        return this.cs.updateContest(info);
    }

    public updateCategories(info: Category[]) {
        return this.cts.setContestCategories(info, this.currentId);
    }

    public updatePrices(info: Price[]) {
        return this.ps.updatePrices(info, this.currentId);
    }

    public updateAwardRules(info: AwardRule[]) {
        return this.ars.setAwardRules(this.currentId, info);
    }

    public updateContestRules(info: TextAsset) {
        return this.cs.setContestRules(info, this.currentId);
    }

    public updateContestRulesPdf(pdf: any) {
        return this.cs.setContestRulesPdf(pdf, this.currentId);
    }

    public updateContestMarkdownFile(markdown: any) {
        return this.cs.setContestRulesMarkdownFile(markdown, this.currentId);
    }

    public updateSponsors(info: Sponsor[]) {
        return this.cs.setContestSponsors(info, this.currentId);
    }

    public updateProductPickup(info: ProductPickup) {
        return this.pps.createProductPickup(this.currentId, info);
    }

    public updateContestant(info: School) {
        return this.pts.updateSchool(this.currentId, info);
    }

    public updateFinalReport(info: TextAsset) {
        return this.cs.setFinalReport(info, this.currentId);
    }

    public updateFinalReportPdf(report: any): Promise<any> {
        return this.cs.setFinalReportPdf(report, this.currentId);
    }

    public updateFinalReportMarkdown(report: any): Promise<any> {
        return this.cs.setFinalReportMarkdown(report, this.currentId);
    }

    public exchangePrice(price: string, points: number) {
        return this.ps.exchangePrice(this.currentId, price, points);
    }

    //* ************************** Delete methods ******************************//

    public deleteCategories(info: string[]) {
        return this.cts.deleteCategoryGroups(this.currentId, info);
    }

    public deletePrices(info: string[]) {
        return this.ps.deletePrices(this.currentId, info);
    }

    public deleteProductPickup(id: string) {
        return this.pps.deleteProductPickup(this.currentId, id);
    }

    public deleteContestant(id: string) {
        return this.pts.deleteSchool(this.currentId, id);
    }

    public deleteNewContestant(id: string) {
        return this.pts.deleteNewSchool(this.currentId, id);
    }

    //* ******************************* Get classes *****************************//

    public getProductPickups() {
        return this.pps.getProductPickups(this.currentId);
    }

    public getNewSchools(id: string) {
        return this.pts.getNewSchools(id);
    }

    public getExchangedPrices() {
        return this.ps.getExchangedPrices(this.currentId);
    }
}
