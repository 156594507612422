/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { Contest, AwardRule, Price, Category } from './contest.model';
import { School } from './participant.model';
import { ContestService } from './contest.service';
import { PricesService } from './prices.service';
import { CategoriesService } from './categories.service';
import { ParticipantsService } from './participants.service';
import { ProductPickupService } from './product-pickup.service';
import { AwardRulesService } from './award-rules.service';
import { ProductPickup } from './product-pickup.model';
import { RankingItem } from './ranking.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { NewsItem } from './news-item.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WebService {

    contest: Contest;

    prices: Price[];

    awardRules: any[];

    categories: Category[];

    schools: School[];

    productPickups: ProductPickup[];

    news: NewsItem[];

    contestLoaded = new BehaviorSubject<Contest | null>(null);

    pickupsLoaded = new BehaviorSubject<boolean>(false);

    pricesLoaded = new BehaviorSubject<boolean>(false);

    categoriesLoaded = new BehaviorSubject<boolean>(false);

    awardRulesLoaded = new BehaviorSubject<boolean>(false);

    newsLoaded = new BehaviorSubject<boolean>(false);

    constructor(
        private cs: ContestService,
        private ps: PricesService,
        private cts: CategoriesService,
        private pts: ParticipantsService,
        private pps: ProductPickupService,
        private as: AwardRulesService,
        private firestore: AngularFirestore,
    ) { }

    public setContest(id: string): void {
        this.contestLoaded.next(null);
        this.cs.getContest(id).subscribe(data => {
            this.contest = data;
            this.contestLoaded.next(this.contest);
        });
        this.pts.getSchools(id).subscribe(data => {
            this.schools = data;
        });
        this.ps.getPrices(id).subscribe(data => {
            this.prices = data;
            this.pricesLoaded.next(true);
        });
        this.cts.getContestCategories(id).subscribe(data => {
            this.categories = data;
            this.categoriesLoaded.next(true);
        });
        this.pps.getProductPickups(id).subscribe(data => {
            this.productPickups = data;
            this.pickupsLoaded.next(true);
        });
        this.as.getStaticAwardRules(id).subscribe(data => {
            this.awardRules = data;
            this.awardRulesLoaded.next(true);
        });
        this.getNews(id).subscribe(data => {
            console.log(data);
            this.news = data;
            this.newsLoaded.next(true);
        });
    }

    public createSchool(info: School) {
        info.position = this.schools.length + 1;
        return this.pts.createSchool(this.contest, info);
    }

    // Helper methods

    public totalWeightForCategory(categoryId: string) {
        if (this.productPickups) {
            let total = 0;
            this.productPickups.forEach(item => {
                if (item.category === categoryId) {
                    total += Number(item.weight);
                }
            });
            return total;
        } else { return 0; }
    }

    public getTotalWeight(): number {
        if (this.productPickups) {
            let total = 0;
            this.productPickups.forEach(item => {
                total += Number(item.weight);
            });
            return total;
        } else { return 0; }
    }

    public totalPointsForCategory(categoryId: string) {
        if (this.productPickups) {
            let total = 0;
            this.productPickups.forEach(item => {
                if (item.category === categoryId) {
                    if (item.totalPoints) {
                        total += Number(item.totalPoints);
                    }
                }
            });
            return total;
        } else { return 0; }
    }

    public getTotalPoints(): number {
        if (this.productPickups) {
            let total = 0;
            this.productPickups.forEach(item => {
                if (item.totalPoints) {
                    total += Number(item.totalPoints);
                }
            });
            return total;
        } else { return 0; }
    }

    public getSchoolsRankingForCategory(categoryId: string) {
        if (this.productPickups) {
            const rankItems: RankingItem[] = this.schools.map((item: School) => {
                const r = new RankingItem();
                r.title = item.name;
                r.weight = 0;
                r.ratioPoints = 0;
                r.totalPoints = 0;
                r.location = item.city + ' (' + item.province + ')';
                const pickups = this.productPickups.filter((p: ProductPickup) => p.school === item.id && p.category === categoryId);
                pickups.forEach((t: ProductPickup) => {
                    r.weight += Number(t.weight);
                    r.totalPoints += Number(t.totalPoints);
                });
                r.ratioPoints = Math.round(r.totalPoints / item.studentCount);
                return r;
            }).filter((item: RankingItem) => item.totalPoints > 0);
            if (this.contest.noRatioPoints) {
                return rankItems.sort((n1, n2) => n2.totalPoints - n1.totalPoints);
            }
            return rankItems.sort((n1, n2) => n2.ratioPoints - n1.ratioPoints);
        } else {
            return [];
        }
    }

    public getExchangedPrices() {
        return this.ps.getExchangedPrices(this.contest.id);
    }

    private getNews(id: string): Observable<NewsItem[]> {
        return this.firestore
            .collection<NewsItem>('news', (ref) => ref.where('contest', '==', id)
            .orderBy('date', 'desc')).snapshotChanges()
            .pipe(
                map((actions) =>
                    actions.map((a) => {
                        const data = a.payload.doc.data();
                        const itemId = a.payload.doc.id;
                        return { id: itemId, ...data };
                    })
                )
            );
    }
}
