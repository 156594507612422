import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Contest, Category, CategoryPoints, AwardRule } from './contest.model';
import { TextAsset, MarkdownAsset } from './contest.model';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AboutUsService {

    collectionRef: AngularFirestoreCollection;

    constructor(
        private af: AngularFirestore,
        private storage: AngularFireStorage
    ) {
        this.collectionRef = this.af.collection('about-us');
    }

    public async setAboutUs(asset: TextAsset) {
        const r = asset.images.map(item => {
            if (item.imageFile) {
                return {
                    id: item.id,
                    url: ''
                };
            } else {
                return {
                    id: item.id,
                    url: item.url
                };
            }
        });
        await this.collectionRef.doc('value').set({
            text: asset.text,
            images: r
        });
        asset.images.forEach((masset: MarkdownAsset, index: number) => {
            if (masset.imageFile) {
                this.storage.upload('/aboutImages/' + Date.now(), masset.imageFile).then(data => {
                    data.ref.getDownloadURL().then(url => {
                        asset.images[index].url = url;
                        const nr = asset.images.map(item => ({
                            id: item.id,
                            url: item.url
                        }));
                        this.collectionRef.doc('value').update({
                            text: asset.text,
                            images: nr
                        });
                    });
                });
            }
        });
    }

    public getAboutUs(): Observable<TextAsset> {
        return this.collectionRef.doc('value').valueChanges();
    }
}
