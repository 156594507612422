import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { Contest } from './contest.model';
import { ContestService } from './contest.service';
import { Observable, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class LandingService {

    public contests: Observable<Contest[]>;

    constructor(
        private cs: ContestService
    ) {
        this.contests = this.cs.getContestList();
    }


}
