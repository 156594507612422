import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo
} from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInToAdmin = () => redirectLoggedInTo(['admin']);

const routes: Routes = [
    {
        path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    },
    {
        path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToAdmin }
    },
    {
        path: 'concurso/:id', loadChildren: () => import('./web-contest/web-contest.module').then(m => m.WebContestModule)
    },
    {
        path: '', loadChildren: () => import('./web-showcase/web-showcase.module').then(m => m.WebShowcaseModule)
    },
    // {
    //     path: '', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
    // },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
