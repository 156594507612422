import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { Contest, Sponsor } from './contest.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SponsorsService {

    sponsorsRef: AngularFirestoreCollection<Sponsor>;

    constructor(
        private db: AngularFirestore,
        private storage: AngularFireStorage
    ) {
        this.sponsorsRef = this.db.collection('modelSponsors');
    }

    // Create new sponsor
    public createSponsor(sponsor: Sponsor) {
        const s = {
            image: sponsor.image ? sponsor.image : null,
            url: sponsor.url ? sponsor.url : null,
            name: sponsor.name ? sponsor.name : null
        };
        return this.sponsorsRef.add(s).then(() => sponsor);
    // let link = sponsor.url;
    // return this.storage.upload('/sponsorImages/'+Date.now(),sponsor.image).then(
    //   data => {
    //     return data.ref.getDownloadURL().then(
    //       url => {
    //         let obj: Sponsor = {
    //             image: url,
    //             url: link
    //         };
    //         return this.sponsorsRef.add(obj).then(() => {
    //           return obj
    //         });
    //       }
    //     )
    //   }
    // );
    }

    // Get Model Sponsors
    public getSponsors(): Observable<Sponsor[]> {
        return this.sponsorsRef.snapshotChanges().pipe(
            map(data => data.map(item => {
                const pp = item.payload.doc.data() ;
                pp.id = item.payload.doc.id;
                return pp;
            }))
        );
    }

    // Delete a sponsors
    public deleteSponsor(id: string) {
        return this.sponsorsRef.doc(id).delete();
    }

    public updateSponsor(id: string, updatedSponsor: Sponsor): Promise<void> {
        const sponsorToUpdate = { ...updatedSponsor };
        delete sponsorToUpdate.id; // Remove id property if it exists in the updated data
        return this.sponsorsRef.doc(id).update(sponsorToUpdate);
    }
}
