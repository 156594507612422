/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Contest, Category, CategoryPoints } from './contest.model';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    categoryTemplatesRef: AngularFirestoreCollection;
    contestsRef: AngularFirestoreCollection<Contest>;

    constructor(
        private db: AngularFirestore
    ) {
        this.categoryTemplatesRef = this.db.collection('categoryTemplates');
        this.contestsRef = this.db.collection('contests');
    }

    //* ************************* Template functions ***************************//

    public createTemplate(cat: Category) {
        const categories = cat.products.map(item => ({
            identifier: item.identifier,
            description: item.description,
            points: item.points
        }));
        return this.categoryTemplatesRef.doc(cat.id).set({
            categories
        });
    }

    public getTemplates(): Observable<Category[]> {
        return this.categoryTemplatesRef.snapshotChanges().pipe(
            map((data: any) => data.map(group => {
                const categories = group.payload.doc.data();
                const cat = new Category();
                cat.id = group.payload.doc.id;
                cat.products = categories.categories.map(item => {
                    const parsed: CategoryPoints = {
                        identifier: item.identifier,
                        description: item.description,
                        points: item.points
                    };
                    return parsed;
                });
                return cat;
            }))
        );
    }

    //* ************************* Contest functions ***************************//

    public setContestCategories(categories: Category[], id: string) {
        const calls = [];
        for (const cat of categories) {
            const items = [];
            for (const points of  cat.products) {
                items.push({
                    identifier: points.identifier,
                    description: points.description,
                    points: points.points
                });
            }
            calls.push(
                this.contestsRef.doc(id).collection('categories').doc(cat.id).set({
                    categories: items
                }
                ).then(() => {
                    if (cat.isTemplate) {
                        this.createTemplate(cat).catch(() => console.log('Error creating template'));
                    }
                })
            );
        }
        return forkJoin(calls);
    }

    public getContestCategories(id: string): Observable<Category[]> {
        return this.contestsRef.doc(id).collection('categories').snapshotChanges().pipe(
            map((data: any[]) => data.map((group: any) => {
                const categories = group.payload.doc.data();
                const cat = new Category();
                cat.id = group.payload.doc.id;
                cat.products = categories.categories.map((item) => {
                    const parsed: CategoryPoints = {
                        identifier: item.identifier,
                        description: item.description,
                        points: item.points
                    };
                    return parsed;
                });
                return cat;
            }))
        );
    }

    public deleteCategoryGroup(id: string, ownerId: string): void {
        this.contestsRef.doc(ownerId).collection('categories').doc(id).delete()
            .catch(() => console.log('Error deleting category group'));
    }

    public deleteCategoryGroups(ownerId: string, categories: string[]) {
        const calls = [];
        categories.forEach(item => {
            calls.push(this.contestsRef.doc(ownerId).collection('categories').doc(item).delete());
            calls.push(this.contestsRef.doc(ownerId).collection('awardRules').doc(item).delete());
        });
        return forkJoin(calls);
    }
}
